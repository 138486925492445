import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import { ConstructionCard, Form, FieldGroup, MaterialCheckbox, ProgressSpinner, StringUtil } from 'reactifi';
import { groupBy, sortBy } from 'lodash';

const OrgBaseCapabilitiesCard = props => {
  const {
    canEditCapabilities,
    capabilities,
    capabilitySelect,
    isLoading,
    onCancelCapabilities,
    onSaveCapabilities,
    sanitizeCapabilities,
    selectedCapabilities
  } = props;

  let groups = sortBy(capabilities, 'label');
  groups = groupBy(groups, 'group_name');
  const buttonGroupClass = canEditCapabilities() ? 'form-controls-container row text-end' : 'hidden';

  if (isLoading) {
    return <ProgressSpinner />;
  }

  return (
    <ConstructionCard
      alwaysExpanded={true}
      title={i18n.t('Base Capabilities')}
      dataObjectType="base-capabilities"
      className="m-b-30"
    >
      <Form
        buttonGroupClass={buttonGroupClass}
        data={sanitizeCapabilities(selectedCapabilities)}
        buttonStyle="primary"
        onCancel={onCancelCapabilities}
        addAction={onSaveCapabilities}
        cancelButton={canEditCapabilities()}
        saveButton={canEditCapabilities()}
        saveButtonAreaClassName="form-buttons-container-right"
        saveButtonDataAction="save-base-capabilities"
        disableButtons={false}
      >
        <FieldGroup className="org-capabilities-container">
          {['learning_context', 'integrations', 'advanced_features'].map(
            (groupName) => {
              const capabilities = groups[groupName];
              if (!capabilities) {
                return null;
              }
              return (
                <FieldGroup
                  key={groupName}
                  className="capabilities-field-group"
                >
                  <h5 className="ui-default">
                    {StringUtil.UnderscoresToTitleCase(groupName)}
                  </h5>
                  {capabilities.map(capability => {
                    return (
                      <FieldGroup
                        className="capability-select"
                        key={capability.name}
                      >
                        <MaterialCheckbox
                          data-object-type="organization"
                          data-object-attribute="capabilities"
                          data-object-id={capability.id}
                          name={capability.name}
                          onChange={(value) =>
                            capabilitySelect(capability.name, value)
                          }
                          disabled={!canEditCapabilities()}
                          tooltip={{
                            name: capability.name,
                            tooltip: capability.description,
                            tooltipPosition: "top",
                            label: i18n.t('More information about %s', {
                              postProcess: 'sprintf',
                              sprintf: [capability.label]
                            })
                          }}
                        >
                          {capability.label}
                        </MaterialCheckbox>

                      </FieldGroup>
                    );
                  })}
                </FieldGroup>
              );
            }
          )}
        </FieldGroup>
      </Form>
    </ConstructionCard>
  );
};

OrgBaseCapabilitiesCard.propTypes = {
  capabilities: PropTypes.array,
  canEditCapabilities: PropTypes.func,
  capabilitySelect: PropTypes.func,
  onSaveCapabilities: PropTypes.func,
  onCancelCapabilities: PropTypes.func,
  isLoading: PropTypes.bool,
  sanitizeCapabilities: PropTypes.func,
  selectedCapabilities: PropTypes.object
};

export default OrgBaseCapabilitiesCard;
